@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Bebas Neue', sans-serif;
}
.text-custom {
    font-family: 'Courier New', Courier, monospace;
    color: white;
}
