/* En tu archivo CSS global */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

.font-serif {
  font-family: 'Merriweather', serif;
}
@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  .animate-blink {
    animation: blink 1s step-end infinite;
  }